import React, { useEffect, useState } from "react";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import bankingDetailsService from "../../services/bankingDetailsService";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import InfoIcon from '@material-ui/icons/Info';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Bankdata from '../../Data/bank.json'
import clsx from 'clsx';
import dynamoClient from "../../services/dynamo-api";


const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    appBar1: {
        position: 'relative',
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(700 + theme.spacing(2) * 2)]: {
            width: 800,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            scrollMarginRight: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },

    group: {
        width: 'auto',
        height: 'auto',
        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection: 'row',
    },

    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },

    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    fabProgress: {
        color: "#4353FF",
        position: 'absolute',
        top: 128,
        left: 380,
        zIndex: 1,
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    submitButton: {
        backgroundColor: '#4353FF',
        "&:hover": {
            backgroundColor: "#4353FF"
        }
    },
    formTest: {
        marginTop: 0,
        marginBottom: 0
    }
}));

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Metropolitan GetUp is part of Momentum Metropolitan Life Limited, an authorised financial service (FSP 44673) and registered credit provider (NCRCP173), and rated B-BBEE level 1.'}
        </Typography>

    );
}

export default function BankingDetailsForm(props) {
    const classes = useStyles();
    const [accountHolderTitle, setAccountHolderTitle] = useState("");
    const [accountHolderInitial, setAccountHolderInitial] = useState("");
    const [bankName, setBankName] = useState("");
    const [accountType, setAccountType] = useState("");
    const [accountHolderName, setAccountHolderName] = useState("");
    const [accountHolderSurname, setAccountHolderSurname] = useState("");
    const [accountNumber, setAccountNumber] = useState("");
    const [branchCode, setBranchCode] = useState("");
    const [checked, setChecked] = React.useState(false);
    const [bankBranchCodeName, setbankBranchCodeName] = useState([]);
    const [accountHolderIdNumber, setAccountHolderIdNumber] = useState("");
    const [lexId, setlexId] = useState();
    const [subChannel, setSubChannel] = useState();
    // const [lexInitial, setlexInitial] = useState();

    const [DialogueOpen, setDialogueOpen] = useState(false);
    const [DialogueCPBOpen, setDialogueCPBOpen] = useState(false);
    //Error Validation Handling
    const [accountHolderTitleErr, setAccountHolderTitleErr] = useState({});
    const [accountHolderInitialErr, setAccountHolderInitialErr] = useState({});
    const [accountHolderSurnameErr, setAccountHolderSurnameErr] = useState({});
    const [accountHolderNameErr, setAccountHolderNameErr] = useState({});
    const [bankNameErr, setBankNameErr] = useState({});
    const [bankNameValue, setBankNameValue] = useState({});
    const [accountTypeErr, setAccountTypeErr] = useState({})
    const [accountNumberErr, setAccountNumberErr] = useState({});
    const [branchCodeErr, setBranchCodeErr] = useState({});
    const [accountHolderIdNumberErr, setAccountHolderIdNumberErr] = useState({});

    const [loading, setLoading] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [disableIdField, setDisableIdField] = React.useState(false);

    const [errorMessageCPB, setErrorMessageCPB] = useState("");
    const buttonClassname = clsx({
        [classes.buttonSuccess]: success,
    });


    const handleDialogueClose = () => {
        setDialogueOpen(false);
    };

    const handleDialogueDisagreeClose = () => {
        setDialogueOpen(false);
    };

    const handleDialogueCPBClose = () => {
        setDialogueCPBOpen(false);
    };

    const handleDialogueCPBOpen = () => {
        setDialogueCPBOpen(true);
    };


    const handleDialogueAgreeClose = (e) => {
        debugger;
        console.log(setlexId);
        console.log("Selected Name value: " + bankNameValue);


       let validateBank = bankNameValue;

        const bankingData = {
            sessionid: lexId,
            subChannel: subChannel,
            sessionfrom: 'Facebook',
            accountholderinitial: accountHolderInitial,
            accountholdertitle: accountHolderTitle,
            firstname:accountHolderName,
            accountholdersurname: accountHolderSurname,
            bankname: validateBank,
            accounttype: accountType,
            accountnumber: accountNumber,
            confirmaccountholder: checked,
            branchcode: branchCode,
            idnumber: accountHolderIdNumber,
        }
        if (!loading) {
            setSuccess(false);
            setLoading(true);

        }
        try {

             bankingDetailsService.submitBankingDetails(bankingData).then(res => {
             console.log("SUBMIT BANKING >>>>>>>>> " + JSON.stringify(res));
             if (res.data.httpStatus === 200) {
                    //initailize form data
                    setAccountHolderName("");
                    setAccountHolderInitial("");
                    setAccountHolderTitle("");
                    setAccountHolderSurname("");
                    setBranchCode("");
                    setAccountNumber("");
                    setAccountType("");
                    setBankName("");
                    setBankNameErr("");
                    setBranchCode("");
                    setAccountTypeErr("");
                    setAccountNumberErr("");
                    setBranchCodeErr("");
                    setAccountHolderIdNumber("");
                    setAccountHolderIdNumberErr("");
                    setOpen(true);
                    setSuccess(true);
                    setLoading(false);
                    setDialogueOpen(false);
                    setChecked(false);

                    setTimeout(function () { window.open("", "_self").close(); }, 5000);

                    window.focus();

                } else {
                    //setOpenErr(true);
                    setDialogueOpen(false);
                    setDialogueCPBOpen(true);
                    setLoading(false);
                    setErrorMessageCPB(res.data.response);
                }
            });
        } catch (e) {
            console.log("error : " + e)
            setOpenErr(true);
            setDialogueOpen(false);
        }

    }

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const [open, setOpen] = React.useState(false);
    const [openErr, setOpenErr] = React.useState(false);


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };


    const handleErrorClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenErr(false);
    };


    function isBase64(encodedString) {
        var regexBase64 = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
        return regexBase64.test(encodedString);   // return TRUE if its base64 string.
    }

    useEffect(() => {
        debugger;
        let lexId, accountHolderInitial, accountHolderTitle, accountHolderSurname, accountHolderIdNumber, subChannel;

        let data = window.location.search.substring(1);  // Base64 string
        // let buff = new Buffer(data, 'base64');  //Buffer
        // let UrlParams = Buffer.from(data, 'base64').toString();  //this is the data type that you want your Base64 data to convert to
        let UrlParams = Buffer.from(data).toString();  //this is the data type that you want your Base64 data to convert to
        let params = new URLSearchParams(UrlParams);

        for (const param of params) {
            lexId = params.get('id');
            subChannel = params.get('subChannel');

            accountHolderIdNumber = params.get('idnum');
            accountHolderTitle = params.get('title');
            accountHolderInitial = params.get('initial');
            accountHolderSurname = params.get('surname');
      }


           if(isBase64(accountHolderIdNumber)&&accountHolderIdNumber!==null) {
               accountHolderIdNumber = window.atob(accountHolderIdNumber);
               setDisableIdField(true);
           }



        setlexId(lexId);
        setSubChannel(subChannel);
        setAccountHolderIdNumber(accountHolderIdNumber);
        setAccountHolderTitle(accountHolderTitle);
        setAccountHolderInitial(accountHolderInitial);
        setAccountHolderSurname(accountHolderSurname);
        getBankNameAndBranchcode(subChannel);
    }, []);

    const handleAccountType = (event, values) => {
        setAccountType(event.target.value);
    };


    const getBankNameAndBranchcode = (param) => {
        let i;
        let bankData = [];
        for (i = 0; i < Bankdata.length; i++) {
            if (Bankdata[i].isActive) {
                if(Bankdata[i].name === 'Grindrod' && param && param === 'Atom Chatbot'){
                    delete Bankdata[i]
                }else {
                    bankData.push(Bankdata[i]);
                }
            }
        }
        setbankBranchCodeName(bankData);

       /* bankingDetailsService.getBankNameAndCodesInfo().then(res => {
            if (res.status === 200) {
                let i;
                for (i = 0; i < res.data.length; i++) {
                    if (res.data[i].isActive) {
                        bankData.push(res.data[i]);
                    }
                }
                setbankBranchCodeName(bankData);
            }
        });*/
    }

    const onChangeGetBranchCode = (event, values) => {
        if (values !== null) {
            setBankName(values.description);
            setBranchCode(values.branches[0].code);
            setBankNameValue(values.name);
        } else {
            setBankName('');
            setBranchCode('');
        }
    };

    const handleBankingDetailsSubmit = async (e) => {
        e.preventDefault();
        let data =  await bankingDetailsService.fetchDocument(lexId);

        const isValid = formValidation(data);
        window.scrollTo(0, 0);

        if (isValid) {
            setDialogueOpen(true);
            setSuccess(false);
            setLoading(false);
        }
   }

    const formValidation =  (data) => {

        console.log('**** subChannel : ***' + subChannel);
        let dynamoDBIdNumber;
        if(subChannel == null){
            dynamoDBIdNumber = data.idNumber;
        }


        const accountHolderTitleErr = {};
        const accountHolderInitialErr = {};
        const accountHolderNameErr={};
        const accountHolderSurnameErr = {};
        const bankNameErr = {};
        const accountTypeErr = {};
        const accountNumberErr = {};
        const branchCodeErr = {};
        const accountHolderIdNumberErr = {};

        let isValid = true;
        if (accountHolderTitle != null && typeof (accountHolderTitle) != "undefined") {
            if (accountHolderTitle.trim() === '') {
                accountHolderTitleErr.accountHolderTitleRequired = "Please enter your title.";
                isValid = false;
            }
        } else {
            accountHolderTitleErr.accountHolderTitleRequired = "Please enter your title.";
            isValid = false;
        }

        if (accountHolderTitle != null && typeof (accountHolderTitle) != "undefined") {
            if (accountHolderTitle.length > 10) {
                accountHolderTitleErr.accountHolderTitleLengthCheck = "Please ensure your title is 10 characters";
                isValid = false;
            }
        } else {
            accountHolderTitleErr.accountHolderTitleLengthCheck = "Please ensure your title is 10 characters";
            isValid = false;
        }


        if (accountHolderInitial != null && typeof (accountHolderInitial) != "undefined") {
            if (accountHolderInitial.trim() === '') {
                accountHolderInitialErr.accountHolderInitialRequired = "Please enter a valid initial.";
                isValid = false;
            }
        }

        if (accountHolderInitial != null && typeof (accountHolderInitial) != "undefined") {
            if (accountHolderInitial.length > 2) {
                accountHolderInitialErr.accountHolderInitialLengthCheck = "Please provide a valid initial.";
                isValid = false;
            }
        } else {
            accountHolderInitialErr.accountHolderInitialLengthCheck = "Please provide a valid initial.";
            isValid = false;
        }

        if (accountHolderSurname != null && typeof (accountHolderSurname) != "undefined") {
            if (accountHolderSurname.trim() === '') {
                accountHolderSurnameErr.accountHolderSurnameRequired = "Please enter your surname.";
                isValid = false;
            }
        } else {
            accountHolderSurnameErr.accountHolderSurnameRequired = "Please enter your surname.";
            isValid = false;
        }

        if (accountHolderName != null && typeof (accountHolderName) != "undefined") {
            if (accountHolderName.trim() === '') {
                accountHolderNameErr.accountHolderNameRequired = "Please enter your name.";
                isValid = false;
            }
        } else {
            accountHolderNameErr.accountHolderNameRequired = "Please enter your name.";
            isValid = false;
        }

        if (accountHolderSurname != null && typeof (accountHolderSurname) != "undefined") {
            if (accountHolderSurname.length > 50) {
                accountHolderSurnameErr.accountHolderSurnameLengthCheck = "Please ensure your surname is less than 50 characters.";
                isValid = false;
            }
        } else {
            accountHolderSurnameErr.accountHolderSurnameLengthCheck = "Please ensure your surname is less than 50 characters.";
            isValid = false;
        }

        if (bankName === '') {
            bankNameErr.bankNameRequired = "Please enter your bank name. e.g. Absa.";
            isValid = false;
        }

        if (bankName.length > 40) {
            bankNameErr.bankNameLengthCheck = "Please ensure your bank name is less than 50 characters.";
            isValid = false;
        }

        if (accountType === '') {
            accountTypeErr.accountTypeRequired = "Please select an account type.";
            isValid = false;
        }

        if (accountNumber.length < 9) {
            accountNumberErr.accountNumberLengthCheck = "Please ensure your account number is correct, cannot be less than 9 digits.";
            isValid = false;
        } else if (!Number(accountNumber)) {
            accountNumberErr.accountNumberDigitCheck = "Please ensure your account number is only digits.";
            isValid = false;
        } else if (accountNumber.trim() === '') {
            accountNumberErr.accountNumberRequired = "Please ensure you account number is correct cannot be greater than 11 digits.";
            isValid = false;
        }

        if (branchCode.trim() === '') {
            branchCodeErr.branchCodeRequired = "Please enter a branch code";
            isValid = false;
        }


        if (accountHolderIdNumber != null && typeof (accountHolderIdNumber) != "undefined") {
            if (accountHolderIdNumber.trim() === '') {
                accountHolderIdNumberErr.accountHolderIdNumberRequired = "Please enter a valid ID number";
                isValid = false;
            }else if(accountHolderIdNumber !== dynamoDBIdNumber && subChannel==null){
                  accountHolderIdNumberErr.accountHolderIdNumberRequired = "Please ensure you enter main member ID number";
                  isValid = false;
             } else if (accountHolderIdNumber.trim() !== '') {
                let cb = true;
                let ex;
                if (cb) {
                    ex = /^(((\d{2}((0[13578]|1[02])(0[1-9]|[12]\d|3[01])|(0[13456789]|1[012])(0[1-9]|[12]\d|30)|02(0[1-9]|1\d|2[0-8])))|([02468][048]|[13579][26])0229))(( |-)(\d{4})( |-)(\d{3})|(\d{7}))/;
                }

                let IdNumber = accountHolderIdNumber.trim();
                if (ex.test(IdNumber) === false) {
                    accountHolderIdNumberErr.accountHolderIdNumberRequired = "Please enter a valid ID number";
                    isValid = false;
                }
            }
        } else {
            accountHolderIdNumberErr.accountHolderIdNumberRequired = "Please enter a valid ID number";
            isValid = false;
        }
        setAccountHolderTitleErr(accountHolderTitleErr);
        setAccountHolderInitialErr(accountHolderInitialErr);
        setAccountHolderSurnameErr(accountHolderSurnameErr);
        setAccountHolderNameErr(accountHolderNameErr);
        setBankNameErr(bankNameErr);
        setAccountTypeErr(accountTypeErr);
        setAccountNumberErr(accountNumberErr);
        setBranchCodeErr(branchCodeErr);
        setAccountHolderIdNumberErr(accountHolderIdNumberErr);
        return isValid;
    }


    return (

        <form onSubmit={handleBankingDetailsSubmit}>
            <Snackbar open={open} autoHideDuration={10000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                    Your banking details have been securely saved. Once completed please close the window and continue to the MetGetUp Bot.
                </Alert>
            </Snackbar>

            <Snackbar open={openErr} autoHideDuration={1000} onClose={handleClose}>
                <Alert onClose={handleErrorClose} severity="error">
                    Error submitting banking details.
                </Alert>
            </Snackbar>
            <div>
                <Dialog
                    disableBackdropClick={true}
                    disableEscapeKeyDown={true}
                    open={DialogueOpen}
                    onClose={handleDialogueClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >

                    <DialogTitle id="alert-dialog-title">  &nbsp;{"Confirm Banking Details"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Please confirm that the banking details provided is correct?
                        </DialogContentText>
                    </DialogContent>
                    {loading && <CircularProgress size={24} className={classes.fabProgress} />}
                    <DialogActions>
                        <Button onClick={handleDialogueAgreeClose} color="primary" autoFocus
                                variant="contained"
                                className={buttonClassname}
                                disabled={loading}
                        >
                            Yes
                        </Button>
                        <Button onClick={handleDialogueDisagreeClose} color="primary" variant="contained" className={buttonClassname} disabled={loading}>
                            No
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>


            <div>
                <Dialog
                    disableBackdropClick={true}
                    disableEscapeKeyDown={true}
                    open={DialogueCPBOpen}
                    onClose={handleDialogueCPBClose}
                    aria-labelledby="alert-dialog-tixtle"
                    aria-describedby="alert-dialog-description"
                >

                    <DialogTitle id="alert-dialog-title">  &nbsp;{"Banking Details "}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {errorMessageCPB}
                        </DialogContentText>
                    </DialogContent>
                    {loading && <CircularProgress size={24} className={classes.fabProgress} />}
                    <DialogActions>

                        <Button onClick={handleDialogueCPBClose} color="primary" variant="contained" className={buttonClassname} >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>

            <React.Fragment>
                <CssBaseline />
                <AppBar position="absolute" color="transparent" className={classes.appBar}>
                    <Toolbar variant='regular'>
                        <img src={require("../../images/logo.svg")} width={65} height={65} alt={"getup"} />
                    </Toolbar>
                </AppBar>


                <main className={classes.layout}>
                    <Paper className={classes.paper} elevation={1} variant='outlined'>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            cellspacing: '10px',
                        }}>
                            <img src={require("../../images/lock.svg")} width={80} height={80}
                                alt={"getup"} /> &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp;
                            <Typography variant='body2' color='textSecondary'>
                                At Metropolitan Getup your privacy is as important to us as it is to you. Metropolitan Getup will safeguard the security and confidentiality of all information that you share with us. Your information will not be disclosed to third parties. If you have additional questions regarding the privacy of your personal information  &nbsp;
                                <a href="https://www.metgetup.co.za/">contact us.</a>
                            </Typography>
                        </div>

                    </Paper>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <FormControl variant="outlined" fullWidth>
                                <FormControl variant="outlined" fullWidth margin='none'>
                                    <InputLabel>Title *</InputLabel>
                                    <Select
                                        id="accountHolderTitle"
                                        value={accountHolderTitle}
                                        onChange={(e) => {
                                            setAccountHolderTitle(e.target.value)
                                        }}
                                        label="Title *"
                                        variant="outlined"
                                        fullWidth
                                    >
                                        <MenuItem value={'MR'}>Mr</MenuItem>
                                        <MenuItem value={'MRS'}>Mrs</MenuItem>
                                        <MenuItem value={'MS'}>Ms</MenuItem>
                                        <MenuItem value={'MISS'}>Miss</MenuItem>
                                        <MenuItem value={'MASTER'}>Master</MenuItem>
                                        <MenuItem value={'DR'}>Dr</MenuItem>
                                        <MenuItem value={'PROFESSOR'}>Professor</MenuItem>
                                        <MenuItem value={'ADVOCATE'}>Advocate</MenuItem>
                                        <MenuItem value={'COMMISSIONER'}>Commissioner</MenuItem>
                                        <MenuItem value={'JUDGE'}>Judge</MenuItem>
                                        <MenuItem value={'SIR'}>Sir</MenuItem>
                                        <MenuItem value={'ADMIRAL'}>Admiral</MenuItem>
                                        <MenuItem value={'SERGEANT'}>Sergeant</MenuItem>
                                        <MenuItem value={'COLONEL'}>Colonel</MenuItem>
                                        <MenuItem value={'LIEUTENANT'}>Lieutenant</MenuItem>
                                        <MenuItem value={'COMMANDER'}>Commander</MenuItem>
                                        <MenuItem value={'GENERAL'}>General</MenuItem>
                                    </Select>
                                </FormControl>
                            </FormControl>
                            {Object.keys(accountHolderTitleErr).map((key) => {
                                return <span style={{ color: "red" }}> {accountHolderTitleErr[key]} </span>
                            })}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl variant="outlined" fullWidth>
                                <TextField
                                    id="accountholderinitial"
                                    name="accountholderinitial"
                                    label="Initials *"
                                    value={accountHolderInitial}
                                    onChange={e => setAccountHolderInitial(e.target.value)}
                                    fullWidth
                                    variant='outlined'
                                  />
                            </FormControl>
                            {Object.keys(accountHolderInitialErr).map((key) => {
                                return <div style={{ color: "red" }}> {accountHolderInitialErr[key]} </div>
                            })}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl variant="outlined" fullWidth>
                                <TextField
                                    id="accountholderName"
                                    name="accountholderName"
                                    label="Name *"
                                    value={accountHolderName}
                                    onChange={e => setAccountHolderName(e.target.value)}
                                    fullWidth
                                    variant='outlined'
                                />
                            </FormControl>
                            {Object.keys(accountHolderNameErr).map((key) => {
                                return <div style={{ color: "red" }}> {accountHolderNameErr[key]} </div>
                            })}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl variant="outlined" fullWidth>
                                <TextField
                                    id="accountholdersurname"
                                    name="accountholdersurname"
                                    label="Surname *"
                                    value={accountHolderSurname}
                                    onChange={e => setAccountHolderSurname(e.target.value)}
                                    fullWidth
                                    variant='outlined'
                                />
                            </FormControl>
                            {Object.keys(accountHolderSurnameErr).map((key) => {
                                return <div style={{ color: "red" }}> {accountHolderSurnameErr[key]} </div>
                            })}
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="accountholderIdNumber"
                                name="accountholderIdNumber"
                                label="Identification Number *"
                                value={accountHolderIdNumber}
                                onChange={e => setAccountHolderIdNumber(e.target.value)}
                                fullWidth
                                variant='outlined'
                                disabled={disableIdField}
                            />
                            {Object.keys(accountHolderIdNumberErr).map((key) => {
                                return <div style={{ color: "red" }}> {accountHolderIdNumberErr[key]} </div>
                            })}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Autocomplete
                                options={bankBranchCodeName}
                                getOptionLabel={(option) => option.description}
                                id="bankName"
                                onChange={onChangeGetBranchCode}
                                renderInput={(params) => <TextField style={{ marginTop: '0px' }} {...params} variant='outlined' label="Select Bank *" margin="normal" value={bankName} />}
                            />
                            {Object.keys(bankNameErr).map((key) => {
                                return <span style={{ color: "red" }}> {bankNameErr[key]} </span>
                            })}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl variant="outlined" fullWidth margin='none'>
                                <InputLabel>Account Type *</InputLabel>
                                <Select
                                    id="accountType"
                                    value={accountType}
                                    onChange={handleAccountType}
                                    label="Account Type *"
                                    variant="outlined"
                                    fullWidth
                                >
                                    <MenuItem value={'Cheque'}>Cheque</MenuItem>
                                    <MenuItem value={'Savings'}>Savings</MenuItem>
                                </Select>
                            </FormControl>
                            {Object.keys(accountTypeErr).map((key) => {
                                return <span style={{ color: "red" }}> {accountTypeErr[key]} </span>
                            })}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="accountnumber"
                                name="accountNumber"
                                label="Account Number *"
                                value={accountNumber}
                                onChange={e => setAccountNumber(e.target.value)}
                                fullWidth
                                variant='outlined'

                            />
                            {Object.keys(accountNumberErr).map((key) => {
                                return <span style={{ color: "red" }}> {accountNumberErr[key]} </span>
                            })}
                        </Grid>

                        <Grid item xs={12} sm={6}>

                            <TextField
                                id="BranchCode"
                                name="BranchCode"
                                label="Branch Code *"
                                value={branchCode}
                                fullWidth
                                variant='outlined'
                            />
                            {Object.keys(branchCodeErr).map((key) => {
                                return <span style={{ color: "red" }}> {branchCodeErr[key]} </span>
                            })}
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormControlLabel
                                control={<Checkbox
                                    color='primary'
                                    checked={checked}
                                    onChange={handleChange}
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />}
                                label={<Typography color='textSecondary' variant='body1'>Please confirm that you are the account holder.</Typography>}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <div style={{
                                display: 'flex',
                                flexDirection: "row",
                                alignItems: 'center'
                            }}>
                                <InfoIcon fontSize='small' color='secondary' />
                                <br />
                                <Typography color='textSecondary' variant='body2' style={{ marginLeft: '5px' }}>
                                    Personal bank information you share with Metropolitan Getup is secured with end-to-end encryption.
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <Button className={classes.submitButton} variant="contained" fullWidth color="primary" type="submit" value="Submit">
                                Submit
                            </Button>
                        </Grid>
                    </Grid>

                    <br /><br />
                    <Copyright />

                </main>
                <br /> <br /><br />

                {/*<AppBar position="absolute" color="transparent" className={classes.appBar1}>*/}
                {/*    <Toolbar>*/}

                {/*    </Toolbar>*/}
                {/*</AppBar>*/}

            </React.Fragment>
        </form>
    );
}

