import axios from 'axios';
import {API_CONFIG} from "../config/api-config";
import {API_TOKEN_CONFIG} from "../config/api-config";
import dynamoClient from "./dynamo-api";

const username = 'getup';
const password = '-<TO){k9x$5&^4Wq=@n;';

const token = username+":"+password;
const encodedToken = Buffer.from(token).toString('base64');

const authHeaders = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    'Authorization': 'Basic '+ encodedToken
};


const DataParams = {
    apiUrl: API_CONFIG,
    apiToken: API_TOKEN_CONFIG
};

const headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
};

const submitBankingDetails = (bankingData) => {

    console.log("ACCESS TOKEN : " + DataParams.apiToken)
    console.log('BANKING DETAILS ------->' + JSON.stringify(bankingData));
    return axios({
        method: 'post',
        url:  DataParams.apiUrl + 'banking/capture/',
        data: bankingData,
        headers: authHeaders
    })
}



const getBankNameAndCodesInfo = () => {
    return axios({
        method: 'get',
        url: 'https://d1auaa2ifhowoy.cloudfront.net/getup-api-gateway/bank.json',
        headers: authHeaders
    });
}

const fetchDocument = async (lexId) => {
    let document =  await dynamoClient.getDocumentById(lexId);
    return document
}

export default {
    fetchDocument,
    submitBankingDetails,
    getBankNameAndCodesInfo
};

