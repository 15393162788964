let API_URL;
let API_TOKEN;

let CREDENTIALS;
let API_DYNAMODB;

const host = window && window.location && window.location.hostname;
console.log("HOST---->" + host)
if(host === 'localhost'){
    API_TOKEN = "Z2V0dXA6cGFzc3dvcmQxMjMj";
    API_URL = 'http://localhost:6780/gu-gateway/';
    API_DYNAMODB = 'funeral-fusion-dev';
}else if (host === 'www.secure-dev.metgetup.co.za'){
    API_TOKEN = "Z2V0dXA6cGFzc3dvcmQxMjMj";
    API_URL = 'https://api-dev.getup.metropolitan.co.za/gu-gateway/';
    API_DYNAMODB = 'funeral-fusion-dev';
}else {
    API_TOKEN = "Z2V0dXA6cHJkZ3VnYXRld2F5I0Aq";
    API_URL = 'https://api.getup.metropolitan.co.za/gu-gateway/';
    API_DYNAMODB = 'funeral-consolidation';
}

console.log('HOST URL ---------- ' + host);
console.log('API URL ---------- ' + API_URL);

CREDENTIALS = {
    "region": "eu-west-1",
    "secretAccessKey": "8usaGevO4a+9nuAsT3YJq2r0yvYzAKz1V+mMWr+F",
    "accessKeyId": "AKIA4WJL2SUG6GG34XIB"
}
export const API_CREDENTIALS = CREDENTIALS;
export const API_CONFIG = API_URL;
export const API_CONFIG_DYNAMODB = API_DYNAMODB;
export const API_TOKEN_CONFIG = API_TOKEN;


