
'use strict';
import {API_CONFIG_DYNAMODB,API_CREDENTIALS} from "../config/api-config";
import * as AWS from 'aws-sdk';
const TABLE = API_CONFIG_DYNAMODB;
AWS.config.update(API_CREDENTIALS);


let client = new AWS.DynamoDB.DocumentClient();
const getDocumentById = async (uid) => {
    const params = {
        TableName: TABLE,
        Key: {
            uid: uid
        }
    };

    return client
        .get(params)
        .promise()
        .then(res => res.Item)
        .catch(err => err);
}

export default {
    getDocumentById
};

